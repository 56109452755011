
nav{
    width: 100%;
    height: 5rem;
    display: grid;
    place-items: center;
    background: var( --color-primary);
    position: fixed;
    top: 0;
    left:0;
    z-index: 99;
    box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue),68%, 42%, 20%);
    transition: var(--transition);
    border-radius: 4rem  ;
    box-shadow: 0 .5rem .5rem #000000;
}

.nav1{
    background: rgba(0,0,0,0.3);
    width: max-content;
    padding: 0.7rem 1.7rem;
    position: fixed;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
  }

.nav__container{
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.nav__logo{

    width: 5rem;
    border-radius: 20%;
    overflow: hidden;
}
.nav__menu{
    display: flex;
    gap: 3rem;
    align-items: center;
    margin-left: auto;
}

.nav__menu a{
   transition:var(--transition) ;
   
}
.nav__menu a:hover{
    color:var(--color-black) ;
 }

 .active-nav::after{
    content:'' ;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    background: yellow;
    position: absolute;
    left: calc(50% - 0.6rem);
    transform: rotate(45deg);
    margin-top: 0.9rem;

 }
#theme__icon{
    background: transparent;
    color: wheat;
    font-size: 2rem;
    cursor: pointer;
}

/* hide nav on tablets or on mobiles  */
    .nav_toggle-btn{
        visibility: hidden;
         
    }

@media screen and (max-width:1024px) {
    .nav_toggle-btn{
        display: inline-block;
        position: absolute;
        right: 0;
        gap: 0;
        visibility: visible;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }
    .nav_toggle-btn svg{
        color: var(--color-white);
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
        
    }
    .nav__container{
        height: 100%;
        
    }
    .nav__menu{
        position:absolute;
        width: 100%;
        
        top: 100%;

        
        flex-direction: column;
        gap: 0;
      
        


    }
    .nav__menu li{
        height: 60px;
        width: 100%;
        box-sizing: -2rem 2rem  5rem rgba(0,0,0,0);
        box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue),68%, 42%, 20%);
        
    }
    
    
    .nav__menu li a{
        background-color: var(--color-black);
        border-radius: 1rem  ;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
        box-sizing: -2rem 2rem  5rem rgba(0,0,0,0);
        box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue),68%, 42%, 20%);
        
    }
    .show_nav{
        display: flex;
    }
    .hide_nav{
        display: none;
    }
}

@media screen and (max-width:600px) {
    .nav_toggle-btn{
        display: inline-block;
        position: absolute;
        right: 0;
        gap: 0;
        visibility: visible;
        background: transparent;
        font-size: 1.8rem;
        cursor:pointer;
    }
    .nav_toggle-btn svg{
        color: var(--color-white);
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
        
    }

    .nav__menu{
        position:absolute;
        width: 100%;
        
        top: 100%;
       
        
        flex-direction: column;
        gap: 0;
        
        background: rgba(0,0,0,0.5);


    }
    .nav__menu li{
        height: 100%;
        width: 100%;
        box-sizing: -2rem 2rem  5rem rgba(0,0,0,0);
        box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue),68%, 42%, 20%);
        
    }
    
    
    .nav__menu li a{
        background-color: var(--color-black);
        border-radius: 1rem  ;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
        box-sizing: -2rem 2rem  5rem rgba(0,0,0,0);
        box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue),68%, 42%, 20%);
        
    }
    .show_nav{
        display: flex;
    }
    .hide_nav{
        display: none;
    }
}