

.container.testim_container{
    width: 100%;
    height: 100px;
    
}
.client_avtar{
    width: 2rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 0rem;
    border: 0.4rem solid var(--color-primary);

}
.testimonial{
    background:var(--color-black);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;

}
.h5{
    color:var(--color-primary);

}
.swiper {
  background-color:var(--color-white);
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;

    object-fit: cover;
  }
  
@media screen and (max-width: 1024px ) {
    .container.testim_container{
        width: 100%;
        height: 120px;
    }
}
@media screen and (max-width:600px ) {

    .container.testim_container{
        width:100%;
        height: 150px;
    }
    
}