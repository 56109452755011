.services__container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 3rem;
}
.service{
    background: var(--color-black);
    border-radius:0 0 2rem 2rem;
    color: var(--color-white);
    border:1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.service:hover{
    background: transparent;
    border-color: var(--color-primary);
    color: var(--color-black);
    cursor: default;
}
.service__head
{
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem ;
    box-shadow: 0 2rem 1rem #000000;
}
.service__head h3{
    color: var(--color-black);
    font-size: 1rem;
    text-align: center;
}
.service__list {
    
    padding: 2rem;
}
.service__list li{

    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}
.service__list-icon{
    color: var(--color-primary);
    margin-top: 2px;
}
.service__list p {
    font-size: 0.9rem;   
}
.g{
    display:inline-flex;
    gap: 16px;
    justify-content: center;
    height: 100%;
    width: 80%;
}
.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 80px;
    margin-left: 2%;
    margin-right: 2%;
    padding: 10px;
    justify-content: center;
    
    
    
  }
  
  .gallery-item {
    overflow: hidden;
    position: relative;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit:inherit;
    display: block;
  }
@media screen and (max-width: 1024px) {

    .services__container{
        grid-template-columns:  1fr 1fr; 
        grid: 2rem;
    }
    .service{
        height: auto;
    }
    
    .gallery {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        gap: 5px;
        margin-left: 8px; 
        
      }
      
      .gallery-item {
        overflow: hidden;
        position: relative;
      }
      
      .gallery-item img {
        width: 100%;
        height: 100%;
        object-fit:inherit;
        display: block;
      }
}
@media screen and (max-width: 600px) {

    .services__container{
        grid-template-columns:1fr; 
        gap: 1.5rem;
    }
    .service{
        height: auto;
    }
    .gallery {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        gap: 5px;
        margin-left: 8px; 
        
      }
      
      .gallery-item {
        overflow: hidden;
        position: relative;
      }
      
      .gallery-item img {
        width: 100%;
        height: 100%;
        object-fit:inherit;
        display: block;
      }

}