.container.contact_container{
    width: 60%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}
.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;    
}
.contact_option{
        background: var(--color-primary);
        padding: 1.2rem;
        border-radius: 1.2rem;
        text-align: center;
        border: 1px solid transparent;
        transition:var(--transition) ;
}
.contact_option:hover{
    background: transparent;
    border-color: var(--color-primary);
    transition:var(--transition) ;
}
.contact_icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact_option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

form{
    display: flex;
    flex-direction: column;    
    gap: 1.2rem;
}

input,textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background:transparent;
    border: 2px solid var(--color-primary);
    resize: none;
}
@media screen and (max-width: 1024px ) {
    .container.contact_container{
        grid-template-columns: 1fr;
        gap: 12%;
    }
}
@media screen and (max-width:600px ) {
    .container.contact_container{
        width: var(--container-width-sm);
    }
    
}