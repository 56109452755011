.experience_container {
    display: grid;
    padding: 1rem;
    gap: 2rem;
  
  }
  
  .experience_container > div {
    background: var(--color-black );
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
  }
  
  .experience_container > div:hover {
    background: transparent;
    border-color:var(--color-light );
    background: var(--color-dark);
    cursor:default;
  }
  
  .experience_container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
  }
  
  .experience_content {
    display: grid;
    background: var(--color-lightness);
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
  
  .experience_details {
    width: 200%;
    height: 10rem;
   display: flex;
   gap: 1rem;
   color: var(--color-white);
  }
  
  .experience_details-icon {
    margin-top: 6px;
    width: 50%;
    height: 60%;
    color: var(--color-primary);
  }
  
    
    
  @media screen and (max-width: 1024px){
   .experience_container {
       grid-template-columns: 1fr;
   }
  
   .experience_container > div {
     width: 80%;
     padding: 2rem;
     margin: 0 auto;
   }
  
   .experience_content {
     padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
  
  .experience_details {
    display: inline;
    width: 200%;
    height: auto;
   gap: 1rem;
   color: var(--color-white);
  }
  
  .experience_details-icon {
    

    width: 100%;
    height: 40%;
    color: var(--color-primary);
  }
  
  }
  
  @media screen and (max-width: 600px){
   .experience_container {
      gap: 1rem;
   }
  
   .experience_container > div {
     width: 100%;
     padding: 2rem 1rem;
     margin: 0 auto;
   }
  
   .experience_content {
     padding: 1rem;
   }
  }