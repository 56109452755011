header{

    height: calc(100vh -5rem);
    display: grid;
    place-items: center;
    background: var(--color-white);
    transition: var(--transition);

}
.header__container{
    display: flex;
    
}
.portfolio_container1{
    gap: 2.5rem;
    margin-top: 2rem;
    background: var(--color-white);
    border-radius:2rem 2rem 2rem 2rem;
    text-align-last:center;
    height: fit-content;
    transition: var(--transition);
}

.portfolio_item1 h3 {
    margin: 1.2rem 0 2rem;
    align-items: center;
    color: #f49020  ;
}
.portfolio_item-cta1{
    display: grid;
    justify-content: center;
    
    gap: 1rem;
    margin-bottom: 1rem;

    
}
@media screen and (max-width: 1024px) {

    .portfolio_container1{
        
        grid-template-columns: 2fr  1fr;
        gap: 2.5rem;
    }
    
}
@media screen and (max-width: 600px) {

    .portfolio_container1{
        grid-template-columns: 1fr;
        gap: 2.5rem;
        text-align-last:left;
    }

}